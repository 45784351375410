export const THEME_CONFIG = {
  /** Numeric Rem value  */
  grid: {
    gridGutterWidth_desktop: 1.5, // 24px
    gridGutterWidth_mobile: 1, // 16px
    gridFullWidth: 76.5, // 1224px
    gridMinWidth: 20, // 320px
    gridColumns: {
      desktop: 12,
      tablet: 4,
      mobile: 2,
    },
  },
  /**
   * Calculated with: https://type-scale.com/?size=18&scale=1.200&text=A%20Visual%20Type%20Scale&font=Poppins&fontweight=400&bodyfont=body_font_default&bodyfontweight=400&lineheight=1.75&backgroundcolor=%23ffffff&fontcolor=%23000000&preview=false
   * Base size: 16
   * Mobile scale: 1.125
   * Desktop scale: 1.2
   */
  fontSize: {
    root: "16px",
    h0_desktop: 4.3, // 69px
    h0_mobile: 1.68, // 27px

    h1_desktop: 3.583, // 57px
    h1_mobile: 1.575, // 25.2px

    h2_desktop: 2.986, // 47.76px
    h2_mobile: 1.476, // 23.6px

    h3_desktop: 2.488, // 39.94px
    h3_mobile: 1.383, // 22.12px

    h4_desktop: 2.074, // 33.17px
    h4_mobile: 1.296, // 20.76px

    h5_desktop: 1.728, // 27.65px
    h5_mobile: 1.215, //  19.44px

    h6_desktop: 1.44, // 22.86px
    h6_mobile: 1.138, // 18.22px

    body_lg_desktop: 1.2, // 19.2px
    body_lg_mobile: 1.067, // 17.07px

    body_md_desktop: 1, // 16px (root)
    body_md_mobile: 1, // 16px (root)

    body_sm_desktop: 0.833, // 13.32px
    body_sm_mobile: 0.833, // 15.01px

    code_desktop: 0.833, // 13.32px
    code_mobile: 0.833, // 15.01px
  },
} as const;
